<template>
    <r-e-dialog :title="title" :visible.sync="dialogVisible" show-footer top="2vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="商铺名称" prop="name">
                <el-input v-model="formPublish.name" placeholder="请输入商铺名称"/>
            </el-form-item>
            <el-form-item label="商铺编号" prop="apartmentNo">
                <el-input v-model="formPublish.apartmentNo" placeholder="请输入商铺编号"/>
            </el-form-item>
            <div class="flex">
                <el-form-item label="所属区域" prop="province">
                    <el-select v-model="formPublish.province" disabled placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name"
                                   :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="city">
                    <el-select v-model="formPublish.city" disabled placeholder="请选择市" @change="cityChange">
                        <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="district">
                    <el-select v-model="formPublish.district" disabled placeholder="请选择区">
                        <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
            </div>
            <!--<el-form-item label="所在商圈" prop="belongingDistrict">
                <el-input v-model="formPublish.belongingDistrict" disabled placeholder="请输入所在商圈"/>
            </el-form-item>-->
            <el-form-item label="所处楼层" prop="floorNum">
                <el-input-number v-model="formPublish.floorNum" :min="1" :max="34" style="width: 215px;"
                                 placeholder="请输入所处楼层"/>
            </el-form-item>
            <el-form-item label="建筑面积" prop="outArea">
                <el-input v-model="formPublish.outArea" placeholder="请输入建筑面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="使用面积" prop="useArea">
                <el-input v-model="formPublish.useArea" placeholder="请输入使用面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="水表编号" prop="waterMeter">
                <el-input v-model="formPublish.waterMeter" placeholder="请输入水表编号"/>
            </el-form-item>
            <el-form-item label="电表编号" prop="electMeter">
                <el-input v-model="formPublish.electMeter" placeholder="请输入电表编号"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import cityCode from "@/assets/json/city_code.json";
    import {apartmentAdd, apartmentUpdate} from '@/api/shops-list';
    import {MessageSuccess, MessageWarning, MessageError} from "@custom/message";

    export default {
        name: "layer-add-shops",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    communityUuid: null,
                    name: null,
                    apartmentNo: null,
                    province: null,
                    city: null,
                    district: null,
                    floorNum: 1,
                    outArea: null,
                    useArea: null,
                    waterMeter: null,
                    electMeter: null,
                    // belongingDistrict: null,
                },
                rules: {
                    name: [{required: true, message: '请输入商铺名称', trigger: 'blur'}],
                    apartmentNo: [{required: true, message: '请输入商铺编号', trigger: 'blur'}],
                    province: [{required: true, message: '请选择省', trigger: 'change'}],
                    city: [{required: true, message: '请选择市', trigger: 'change'}],
                    district: [{required: true, message: '请选择区', trigger: 'change'}],
                    floorNum: [{required: true, message: '请输入所处楼层', trigger: 'blur'}],
                    outArea: [{required: true, message: '请输入建筑面积', trigger: 'blur'}],
                    useArea: [{required: true, message: '请输入使用面积', trigger: 'blur'}],
                    waterMeter: [{required: true, message: '请输入水表编号', trigger: 'blur'}],
                    electMeter: [{required: true, message: '请输入电表编号', trigger: 'blur'}],
                    // belongingDistrict: [{required: true, message: '请输入所在商圈', trigger: 'blur'}],
                },
                title: '',
                provinceList: cityCode,
                cityList: [],
                areaList: [],
                type: 'add',
            };
        },
        components: {},
        async mounted() {
        },
        methods: {
            openDialog(type, data) {
                console.log(data);
                this.type = type;
                this.title = type === "add" ? '新增商铺' : '修改商铺';
                if (type === 'add') {
                    let {province, districtName, city, district, uuid} = data;
                    this.provinceChange(province);
                    this.cityChange(city);
                    let oldFormPublish = {...this.formPublish};
                    this.formPublish = {
                        ...oldFormPublish,
                        // belongingDistrict: districtName,
                        province,
                        city,
                        district,
                        communityUuid: uuid
                    }
                } else {
                    let {province, city} = data;
                    this.provinceChange(province);
                    this.cityChange(city);
                    let oldFormPublish = {...this.formPublish};
                    this.formPublish = {...oldFormPublish, ...data}
                }
                this.dialogVisible = true;
            },

            clickSubmit() {
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let data = this.formPublish;
                        if (this.type === 'add') this.apartmentAdd({...data});
                        else this.apartmentUpdate({...data});
                    }
                });
            },
            clickCancel() {
                this.$parent.handleSearch();
                // this.$refs["formPublish"].resetFields();
                this.formPublish = {
                    uuid: null,
                    communityUuid: null,
                    name: null,
                    apartmentNo: null,
                    province: null,
                    city: null,
                    district: null,
                    floorNum: 1,
                    outArea: null,
                    useArea: null,
                    waterMeter: null,
                    electMeter: null,
                }
                this.cityList = [];
                this.areaList = [];
                this.dialogVisible = false;
            },
            apartmentAdd(data) {
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                apartmentAdd(data).then(res => {
                    MessageSuccess('添加成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => {
                    MessageError('添加失败');
                    loading.close();
                });
            },
            apartmentUpdate(data) {
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                apartmentUpdate(data).then(res => {
                    MessageSuccess('修改成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => {
                    MessageError('修改失败');
                    loading.close();
                });
            },
            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formPublish.city = null;
                this.formPublish.district = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formPublish.district = null;
                this.areaList = area;
            }
        }
    }
</script>

<style scoped>

</style>